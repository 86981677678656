<template>
  <v-container>
    <TestimonialTable :testimonialHeader="testimonialHeader" :testimonialsList.sync="testimonials"></TestimonialTable>

    <v-row class="pt-7">
      <v-col cols="12">
        <v-btn
          color="primary white--text"
          class="px-6"
          small
          depressed
          :disabled="isFetching"
          @click="saveTestimonials"
        >
          儲存
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TestimonialTable from '@/components/TestimonialTable.vue'

export default {
  name: 'Testimonials',
  components: {
    TestimonialTable,
  },
  data() {
    return {
      testimonialHeader: [
        { text: '客戶名稱', value: 'name', minWidth: '125px', width: '15%' },
        { text: '評價', value: 'description', minWidth: '300px', width: '70%' },
        { text: '', value: 'editBtn', width: '15%' },
        { text: '', value: 'deleteBtn', width: '15%' },
      ],
      testimonials: [],
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getTestimonials() {
      try {
        const payload = {
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('get_system', payload)
        this.$func.log('-----Get Testimonials-----')
        this.$func.log(result)

        this.testimonials = result.testimonials
        this.id = result.id
      } catch (error) {
        this.$func.log('-----Get Testimonials Fail-----')
        this.$func.log(error)
        this.testimonials = []
      } finally {
        this.setFetching(false)
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },
    async saveTestimonials() {
      if (!this.isFetching) {
        this.setFetching(true)

        try {
          const payload = {
            id: parseInt(this.id),
            verify_token: this.getAdminToken(),
            testimonials: this.testimonials,
          }

          const result = await this.$XHR.api('cms_update_testimonials', payload)
          this.$func.log('-----Update Testimonials-----')
          this.$func.log(result)

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: null,
            refresh: true,
          })

          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('-----Update Testimonials Fail-----')
          this.$func.log(error)

          let message = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            message = '沒有權限'
          } else if (error.data === 'system data does not exist') {
            message = '資料不存在'
          }

          this.setFetching(false)
          this.setDialogMessage({
            message: message,
            isError: true,
            returnLink: null,
          })
          this.setShowDialog(true)
        }
      }
    },
  },
  async created() {
    this.setFetching(true)
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.getTestimonials()
    }
  },
}
</script>
